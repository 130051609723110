function Footer() {
    
    /**
    * Make the stripe payment module visible.
    */
    const openPrivacyPolicy = () => {
        const privacyPolicyDialogWrapper = document.getElementById("privacyPolicyDialogWrapper");
        privacyPolicyDialogWrapper.style.display = "flex";
        const mainBody = document.getElementById("mainBody");
        mainBody.style.overflow = "hidden";
    };

    const closePrivacyPolicy = () => {
        const privacyPolicyDialogWrapper = document.getElementById("privacyPolicyDialogWrapper");
        privacyPolicyDialogWrapper.style.display = "none";
        const mainBody = document.getElementById("mainBody");
        mainBody.style.overflow = "inherit";
    };

    const openTermsOfUse = () => {
        const termsOfUseDialogWrapper = document.getElementById("termsOfUseDialogWrapper");
        termsOfUseDialogWrapper.style.display = "flex";
        const mainBody = document.getElementById("mainBody");
        mainBody.style.overflow = "hidden";
    };

    const closeTermsOfUse = () => {
        const termsOfUseDialogWrapper = document.getElementById("termsOfUseDialogWrapper");
        termsOfUseDialogWrapper.style.display = "none";
        const mainBody = document.getElementById("mainBody");
        mainBody.style.overflow = "inherit";
    };

    return (


        <footer id="main-footer" className="noise">
            <div className="footerImagesDiv">
            </div>
            
            <div className="footerCopyrightDiv">
                <p>&copy; {new Date().getFullYear()} Language Simp</p>
            </div>

            <div className="footerLinksDiv">
                <p>
                <a href="mailto:contact@languagesimp.com">Contact</a>&nbsp; // &nbsp;<a href="https://languagesimp.com/privacy_policy">Privacy Policy</a>&nbsp; // &nbsp;<a href="https://languagesimp.com/terms_of_use">Terms of Use</a> 
                </p>
            </div>
            
        	<div id="privacyPolicyDialogWrapper">
                <div id="privacyPolicyDialog">
                <button id="closePrivacyPolicy" onClick={closePrivacyPolicy}>&times;</button>
                    <h1>Privacy Policy</h1>
                    <p><strong>Language Simp</strong> understands the importance of your personal privacy. Therefore, we have created this Privacy Policy so that you know how we use and disclose your information when you make it available to us. This Privacy Policy outlines our practices regarding information collection and usage on the website located at <a href="http://www.languagesimp.com">www.languagesimp.com</a>, the related mobile website, and the mobile application (collectively, the “Websites”) and the service provided thereon (the “Service”).

                    <br /><br />
                    By using or accessing the Websites and the Service, you signify your agreement to be bound by this Privacy Policy. 

                    <br /><br />
                    <strong>IF YOU DO NOT AGREE TO THIS PRIVACY POLICY, YOU MAY NOT ACCESS OR OTHERWISE USE OUR SERVICE OR PARTICIPATE IN OUR SERVICES.</strong>
                    </p>

                    <h2>Key Aspects</h2>
                    <p>Key aspects of our privacy practices described in this Privacy Policy include the following explanations:</p>
                    <ul>
                        <li>The information we collect and why we collect it;</li>
                        <li>How we use that information;</li>
                        <li>How we share information; and</li>
                        <li>The choices we offer.</li>
                    </ul>

                    <h2>Personally Identifiable Information That We Collect</h2>
                    <p>We may ask you for, or you may voluntarily submit, personally identifiable information when you are using the Service. This information may include, but is not limited to:</p>
                    <ul>
                        <li>Your name;</li>
                        <li>Your contact information (including, without limitation, address and email address);</li>
                        <li>Your IP address; and</li>
                        <li>Other personal information.</li>
                    </ul>

                    <h2>Non-Personal or Aggregate Information That We Collect</h2>
                    <p>When you access our Service, we may automatically collect non-personally identifiable information, such as IP host address, web pages viewed, browser type, operating system, referring service, search information, device type, and usage patterns. Aggregated demographic information may also be collected without identifying any individual.</p>

                    <p>If you believe that we have inadvertently collected your personal information, please notify us at <a href="mailto:support@languagesimp.com">support@languagesimp.com</a>.</p>

                    <h2>Information Usage</h2>
                    <p>We will use your personally identifiable information as described below, unless you have provided consent for other uses:</p>
                    <ul>
                        <li>To provide the Service;</li>
                        <li>To respond to inquiries or requests;</li>
                        <li>To send emails about our services (without sharing your information with third parties for direct contact unless consented);</li>
                        <li>To share information with third-party vendors as needed;</li>
                        <li>To comply with legal obligations or investigate violations;</li>
                        <li>To transfer information in the event of a sale, merger, or corporate change; and</li>
                        <li>To share data with affiliates with similar privacy practices.</li>
                    </ul>
                    <p>Non-personally identifiable or aggregate information may be used and shared for any lawful purposes.</p>

                    <h2>Cookies and Similar Technologies</h2>
                    <p>We use cookies and similar technologies to enhance your experience. These cookies may help track user interactions, improve our services, and support analytics or advertising. You can manage cookie settings through your browser preferences. For details on opting out of Google Analytics, visit <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.</p>

                    <h2>Security</h2>
                    <p>We employ reasonable measures to protect your information but cannot guarantee complete security due to the open nature of the Internet. You assume the risk of data breaches when using our Service.</p>

                    <h2>Children's Privacy</h2>
                    <p>We do not knowingly collect information from children under 13 without parental consent, in compliance with COPPA. If such information is inadvertently collected, we will delete it promptly.</p>

                    <h2>Changes to This Privacy Policy</h2>
                    <p>We reserve the right to modify this Privacy Policy at any time. Continued use of the Service following updates signifies acceptance of changes.</p>

                    <h2>Contact</h2>
                    <p id="privacyPolicyLastP">If you have questions about this Privacy Policy, please contact us at <a href="mailto:contact@languagesimp.com">contact@languagesimp.com</a>.</p>        
                </div>
            </div>


            <div id="termsOfUseDialogWrapper">
                <div id="termsOfUseDialog">
                    <button id="closeTermsOfUse" onClick={closeTermsOfUse}>&times;</button>
                    <h1>Terms of Use</h1>
                    <p><strong>Effective Date:</strong> [Insert Date]</p>
                    <p>These Terms of Use (“Terms”) govern your use of the website located at <a href="http://www.languagesimp.com">www.languagesimp.com</a> (the “Website”), the related mobile website, and any associated mobile applications (collectively, the “Sites”), as well as any services, digital content, and features made available through the Sites (collectively, the “Services”). By accessing or using the Sites and Services, including any purchase, download, or use of a copyrighted eBook available through the Sites (the “eBook”), you agree to be bound by these Terms and our Privacy Policy. If you do not agree to these Terms, please discontinue use of the Sites and Services immediately.</p>

                    <h2>1. Eligibility and Access</h2>
                    <ul>
                        <li><strong>Eligibility:</strong> You must be at least the age of majority in your jurisdiction of residence to access and use the Services. By using the Services, you represent that you meet this eligibility requirement.</li>
                        <li><strong>Access:</strong> You are responsible for ensuring that your access to and use of the Services complies with all applicable laws, rules, and regulations. We reserve the right to suspend, restrict, or terminate your access at any time without notice if you violate these Terms.</li>
                    </ul>

                    <h2>2. Intellectual Property Rights</h2>
                    <ul>
                        <li><strong>Ownership:</strong> All content provided on the Sites and through the Services—including text, graphics, logos, images, designs, software, and the eBook—is owned by or licensed to [Company Name] and is protected by applicable copyright, trademark, and other intellectual property laws.</li>
                        <li><strong>License to Use the eBook:</strong> Subject to your compliance with these Terms and applicable payment terms, you are granted a non-exclusive, non-transferable, revocable license to download and use the eBook solely for your personal, non-commercial use. You may not reproduce, distribute, modify, or create derivative works from the eBook, except as expressly permitted by law.</li>
                        <li><strong>Prohibited Use:</strong> You may not use the Sites, Services, or eBook for any unlawful purpose, to infringe upon any intellectual property rights, or in any manner that could damage, disable, overburden, or impair the Sites or Services. You agree not to circumvent, disable, or otherwise interfere with security-related features or any features that prevent or restrict use or copying of content.</li>
                    </ul>

                    <h2>3. User Conduct and Responsibilities</h2>
                    <ul>
                        <li><strong>Compliance:</strong> You agree to comply with all applicable laws, rules, and regulations related to your use of the Sites, Services, and the eBook.</li>
                        <li><strong>User Accounts:</strong> If you are required to create an account to access certain features of the Services, you agree to provide accurate, current, and complete information during registration and to update it as necessary. You are solely responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</li>
                    </ul>

                    <h2>4. Payment and Refunds</h2>
                    <ul>
                        <li><strong>Pricing:</strong> Any fees for access to the eBook or related Services will be clearly stated. By making a purchase, you agree to the listed price and any applicable taxes.</li>
                        <li><strong>Refunds:</strong> Refunds, if any, will be provided in accordance with our posted refund policy. We reserve the right to modify our refund policy at any time.</li>
                    </ul>

                    <h2>5. Privacy Policy</h2>
                    <p><strong>Data Collection and Use:</strong> Our Privacy Policy describes how we collect, use, share, and safeguard your personal information. By using the Sites and Services, you acknowledge that you have read, understood, and agreed to the practices set forth in our Privacy Policy.</p>
                    <p><strong>Cookies and Tracking:</strong> As described in our Privacy Policy, we may use cookies and similar technologies to enhance your experience. By using the Sites and Services, you consent to our use of these technologies.</p>

                    <h2>6. Disclaimers</h2>
                    <ul>
                        <li><strong>No Warranties:</strong> The Sites, Services, and eBook are provided on an “as is” and “as available” basis. To the fullest extent permitted by law, we disclaim all warranties, express or implied, including any implied warranties of merchantability, fitness for a particular purpose, accuracy, or non-infringement.</li>
                        <li><strong>No Guarantee of Availability:</strong> We do not warrant that the Sites, Services, or eBook will be uninterrupted, error-free, secure, or free of viruses or other harmful components.</li>
                    </ul>

                    <h2>7. Limitation of Liability</h2>
                    <ul>
                        <li><strong>Damages:</strong> To the fullest extent permitted by law, we shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from your use of the Sites, Services, or eBook, even if we have been advised of the possibility of such damages.</li>
                        <li><strong>Jurisdictional Limitations:</strong> Some jurisdictions do not allow the exclusion or limitation of certain damages. In such jurisdictions, our liability shall be limited to the maximum extent permitted by law.</li>
                    </ul>

                    <h2>8. Indemnification</h2>
                    <p>You agree to defend, indemnify, and hold harmless [Company Name], its affiliates, officers, directors, employees, agents, and licensors from any and all claims, liabilities, damages, judgments, awards, losses, costs, expenses, and fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms or your use of the Sites, Services, or the eBook.</p>

                    <h2>9. Changes to These Terms</h2>
                    <p>We reserve the right to modify these Terms at any time. If we make changes, we will post the revised Terms on the Sites with a new “Effective Date.” Your continued use of the Sites or Services after the revised Terms have been posted constitutes your acceptance of the changes.</p>

                    <h2>10. Governing Law and Dispute Resolution</h2>
                    <ul>
                        <li><strong>Governing Law:</strong> These Terms and any disputes arising from your use of the Sites, Services, or eBook are governed by and construed in accordance with the laws of [Insert Applicable Jurisdiction], without regard to conflict of law principles.</li>
                        <li><strong>Dispute Resolution:</strong> Any dispute arising under these Terms shall be resolved through binding arbitration in accordance with the rules of [Arbitration Body], and judgment on the award may be entered in any court of competent jurisdiction. You and [Company Name] agree to arbitration on an individual basis, and no class actions are permitted.</li>
                    </ul>

                    <h2>11. Severability</h2>
                    <p>If any provision of these Terms is held to be invalid or unenforceable, the remaining provisions shall remain in full force and effect.</p>

                    <h2>12. Entire Agreement</h2>
                    <p>These Terms, together with our Privacy Policy and any other legal notices posted on the Sites, constitute the entire agreement between you and [Company Name] regarding your use of the Sites, Services, and the eBook, and supersede all prior agreements or understandings.</p>

                    <p id="termsOfUseLastP">By using the Sites and Services, you acknowledge that you have read, understood, and agreed to these Terms of Use.</p>
                </div>
            </div>

            
        </footer>
    );
}

export default Footer