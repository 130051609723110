import "./App.css";
import Payment from "./Payment";
import Return from "./Return";
import Terms from "./Terms";
import Privacy from "./Privacy";

import PurchaseElement from "./components/PurchaseElement/PurchaseElement";
import Header from "./components/Header/Header";
import MainPageContent from "./components/MainPageContent/MainPageContent";
import Footer from "./components/Footer/Footer";

import {
  BrowserRouter as Router,
  Route,
  Routes,
  Outlet
} from "react-router-dom";

function App() {
  // We define a layout component right here:
  function Layout() {
    return (
      <>
        <Header />
        <MainPageContent />
        <Footer />
        {/* The nested route content (Payment/Return) will render here: */}
        <Outlet />
      </>
    );
  }

  return (
    <main>
      <Router>
        <Routes>
          {/* Routes that should show the layout (header, main content, footer) */}
          <Route path="/" element={<Layout />}>
            {/* When path is exactly "/", render <Payment /> in the layout */}
            <Route index element={<Payment />} />
            {/* When path is "/return", render <Return /> in the layout */}
            <Route path="return" element={<Return />} />
          </Route>

          {/* Route that should NOT show the layout — just Terms */}
          <Route path="/terms_of_use" element={<Terms />} />
          <Route path="/privacy_policy" element={<Privacy />} />
        </Routes>
      </Router>
    </main>
  );
}

export default App;
