import { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {EmbeddedCheckoutProvider, EmbeddedCheckout} from "@stripe/react-stripe-js";

export default function Payment() {
  const [stripePromise, setStripePromise] = useState(false);
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    fetch("https://languagesimp.com/config")
      .then(async (response) => {
        const {publishableKey} = await response.json();
        setStripePromise(loadStripe(publishableKey));
      });
  }, []);

  useEffect(() => {
    fetch("https://languagesimp.com/create-payment-intent", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ priceID: "price_1QU1ZuIDeE0gP7K2AeI6JIR1"}),
    })
    .then((res) => res.json())
    .then((data) => setClientSecret(data.clientSecret));
  }, []);

  const hideCheckout = (e) => {
    if (e.target.id === "checkout" || e.target.id === "close-button") {
      const paymentForm = document.getElementById("checkout");
      paymentForm.classList.remove("visible");
      document.getElementsByTagName("body")[0].style.overflow = "auto";
    }
  };

  return (
    <div id="checkout" onClick={hideCheckout}>

      {clientSecret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <div id="checkout-inner-wrapper">
          <div id="close-button-wrapper">
            <button id="close-button">Close</button>
          </div>
          <EmbeddedCheckout />
          </div>
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
}
